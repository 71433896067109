@tailwind base;
@tailwind components;
@tailwind utilities;

th {
  @apply border border-gray-400 px-2 py-1;
}

td {
  @apply border border-gray-400 px-2 py-1;
}

.btn {
  @apply hover:bg-green-800 duration-200 transition ease-in-out delay-100;
}

.skeleton {
  @apply bg-gray-300 animate-pulse rounded;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.PhoneInputInput {
  @apply w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm;
}
